.avatar {
  width: 200px;
}

.Questions_wrapper:first-child {
  padding-top: 10px;
  border-top: 1px solid #959597;
}

.Questions_wrapper:last-child {
  border-bottom: none;
}

.Questions_wrapper {
  margin-top: 10px;
  border-bottom: 0.5px solid #d5d5e4;
}

.time {
  font-size: 15px;
  color: #7a7a7b;
  font-family: Georgia, "Times New Roman", Times, serif;
}

.header {
  color: #7a7a7b;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.header > h2 {
  font-family: monospace;
}
.post_link {
  color: black;
}
.post_link:hover {
  color: rgb(46, 84, 85);
  text-decoration: none;
}
@media screen and (max-width: 1200px) {
  .avatar {
    margin: auto;
  }
}
