.setpassword_section {
  position: relative;
  width: 100%;
  height: 100%;
}

.bg {
  position: fixed;
  width: 100%;
  height: 100%;
}

.setpassword_section .setpassword_left_section {
  position: absolute;
  max-width: 50%;
  width: 100%;
  min-height: 100vh;
  background-color: rgba(27, 27, 27, 0.6);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 2rem;
  margin: 0 2rem;
}

.logo_text_block {
  display: flex;
}

.setpassword_section .logo_text_block img {
  position: relative;
  width: 80px;
  height: auto;
  margin-right: 12px;
}

.setpassword_section .logo_text_block span {
  font-size: 35px;
  font-weight: 400;
  color: #0085ff;
  padding-top: 27px !important;
}

.setpassword_section span {
  font-size: 24px;
  line-height: 32px;
  color: #a4a5a6;
  text-align: center;
  padding-top: 40px;
}

@media screen and (max-width: 850px) {
  .bg {
    width: 100%;
  }
  .setpassword_section .setpassword_left_section {
    max-width: 100%;
    margin: 0;
    padding: 0;
  }
}
