@import url("https://fonts.googleapis.com/css2?family=Benne&display=swap");
.page_title {
  padding: 20px 0 0 10px;
  color: black;
  font-family: "Benne", serif;
  font-size: 25px;
  text-transform: capitalize;
}

.page_section {
  display: flex;
  padding-left: 10px;
  align-items: center;
}

.user_name {
  margin-left: 35px;
  font-weight: bold;
}

.times,
.user_name {
  font-size: 11px;
  color: #7a7a7b;
}

.tags {
  display: flex;
  align-items: center;
}

.tags > p {
  margin-left: 30px;
  padding: 3px 12px;
  font-size: 11px;
  background-color: #e2eef6;
  color: #797f83;
  border-radius: 4px;
}

.answer_wrapper {
  padding-top: 30px;
}
.answer {
  font-size: 20px;
  color: #1b1a1a;
  font-family: "Times New Roman", Times, serif;
}
.answer_time {
  padding-left: 10px;
  font-size: 10px;
}
.answer_delete_dtn {
  margin-left: auto;
}
.answer_delete_dtn:hover {
  color: red;
  cursor: pointer;
}
.answerquestion {
  text-transform: capitalize;
}
.filterdata_body {
  text-transform: capitalize;
  font-size: 16px;
}
.editor {
  height: 200px !important;
  padding: 0 20px !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.btn_color {
  color: #fff !important;
  background-color: #007bff !important;
  border-color: #007bff !important;
  margin-left: auto !important;
}

.btn_color:hover {
  background-color: #2395ff !important;
  transition: 0.4s;
}

@media screen and (max-width: 850px) {
  .tags > p {
    margin-left: 12px;
    padding: 4px 6px;
  }
}

.error {
  color: red;
  font-size: 12px;
  padding-left: 5px;
}