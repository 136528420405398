.tags_wrapper {
  padding-top: 20px;
}

.tag {
  color: #696666;
}

.tag_content {
  max-width: 900px;
  width: 100%;
  font-size: 20px;
  color: #7a7a7b;
  padding: 12px 0;
}

.filter_field {
  max-width: 260px;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  border: 1px solid #111ed3;
  border-radius: 18px;
  padding: 0 18px;
  background-color: #fff;
}

.filter_field img {
  width: 18px;
  padding-top: 2px;
}

.filter_field input {
  border-style: none;
}

.filter_field input:focus {
  outline: none;
}

.tag_container .row {
  margin: 40px 0 !important;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.tag_container img {
  display: flex;
  justify-content: center;
  margin: 2rem auto;
  height: 400px;
}
.tag_container h5 {
  color: #2395ff;
  font-size: 1.25rem;
  text-align: center;
  margin-bottom: 1rem;
}

.tag_block {
  width: 255px;
  height: 120px;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 15px;
  margin-right: 16px;
  margin-bottom: 16px;
  overflow: hidden;
}

.tag_name {
  background: #e2eef6;
  font-size: 18px;
  color: #797f83;
  padding: 0 10px;
  border-radius: 5px;
  cursor: pointer;
}

.tag_name:hover {
  background: #b5d0e5;
}

.about_tag {
  padding-top: 14px;
  font-size: 14px;
  color: #797f83;
}

.notFound img {
  display: flex;
  justify-content: center;
  margin: 2rem 15rem;
  height: 400px;
}
.notFound h5 {
  color: #2395ff;
  font-size: 1.25rem;
  text-align: center;
  margin-bottom: 1rem;
}

@media screen and (max-width: 850px) {
  .tag_block {
    width: 510px;
  }
  .tag_container img {
    margin-top: 2rem;
    height: 250px;
  }
  .notFound img {
    margin: 2rem 0 1rem 0;
    height: 250px;
  }
}
