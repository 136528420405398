.user_wrapper {
  padding-top: 20px;
}

.user {
  color: #696666;
}

.filter_field {
  width: 260px;
  display: flex;
  align-items: center;
  border: 1px solid #111ed3;
  border-radius: 18px;
  padding: 0 18px;
  height: 40px;
  background-color: #fff;
}

.filter_field img {
  width: 18px;
  padding-top: 2px;
}

.filter_field input {
  border-style: none;
}

.filter_field input::-webkit-input-placeholder {
  color: #2395ff;
  opacity: 0.6;
}

.filter_field input::-moz-placeholder {
  color: #2395ff;
  opacity: 0.6;
}

.filter_field input:-ms-input-placeholder {
  color: #2395ff;
  opacity: 0.6;
}

.filter_field input::-ms-input-placeholder {
  color: #2395ff;
  opacity: 0.6;
}

.filter_field input::placeholder {
  color: #2395ff;
  opacity: 0.6;
  font-size: 15px;
}

.filter_field input:focus {
  outline: none;
}
.user_card {
  width: 250px;
}

.user_info {
  padding-left: 10px;
}

.user_email {
  font-size: 11px;
}

.user_name {
  font-size: 14px;
}

.user_container {
  margin-top: 20px;
}

.card_wrapper {
  display: flex;
  margin: 12px 10px;
  width: 190px;
  word-break: break-all;
  /* align-items: center; */
}

.notFound img {
  display: flex;
  justify-content: center;
  margin: 2rem 15rem;
  height: 400px;
}
.notFound h5 {
  color: #2395ff;
  font-size: 1.25rem;
  text-align: center;
  margin-bottom: 1rem;
}

@media screen and (max-width: 850px) {
  .user_container {
    margin: 20px 15px;
    min-height: 100vh;
  }
  .notFound img {
    margin: 0;
    margin-top: 2rem;
    height: 250px;
  }
}
